import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={SignIn} />

        <Route path='/dashboard' component={Dashboard} />

        <Route component={NotFound}/>
      </Switch>
    </BrowserRouter>
  )
};

export default Routes;