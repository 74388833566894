import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const UserHeader = styled.header`
  width: 300px;
  color: #FFF;
  margin-top: 20px;
  font-weight: 500;
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
  
`;

export const Header = styled.div`
  margin: 30px 0;
  color: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  
  img {
    position: absolute;
    right: 0;
    top: -70px;
    width: 120px;
  }

  @media (max-width: 700px) {
    margin: 10px 0;

    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      font-size: 20px;
    }

    img {
      width: 40px;
      top: -50px;
    }
  }
`;

export const HandleBox = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  input, button {
    margin: 0;
    margin-right: 10px;
    height: 50px;
    padding: 10px;
    color: #FFF;
    outline: none;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0, 0.2);
  }

  input {
    &::placeholder {
      color: rgba(255,255,255, 0.7)
    }
  }

  button {
    transition: background-color .2s;
    width: 200px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba(0,0,0, 0.1);
    }
  }
  
  @media (max-width: 700px) {
    flex-direction: column;

    input {
      margin-right: 0;
    }

    input, button {
      width: 100%;
      margin-bottom: 10px;
      font-size: 13px;
      height: 30px;
      padding: 8px;
    }

    button {
      margin-right: 0;
      + button {
        margin-bottom: 0;
      }
    }
  }
`;

export const ListView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  table {
    width: 100%;
    background-color: rgba(0,0,0, 0.1);
    padding: 20px;
    border-radius: 10px;
  }

  tbody tr {
    border: 5px solid #333;
    background-color: rgba(0,0,0, 0.1);
    transition: background-color .2s;

    &:hover {
      background-color: rgba(255,255,255, 0.15);
      cursor: default;
    }
  }

  th, td {
    width: 25%;
    text-align: center;
    color: #FFF;
  }

  th {
    font-weight: 500;
    font-size: 20px;
  }

  td {
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    overflow: hidden;

    svg {
      display: flex;
      justify-content: center;
      align-items: center;
      
      margin: auto auto;
    }
  }

  @media (max-width: 700px) {
    table {
      padding: 5px;
    }

    th {
      font-size: 13px;
    }

    td {
      font-size: 10px;
    }
  }
`;

export const FirstRow = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;

  margin: 10px 0;
  padding: 5px;
  background-color: rgba(0,0,0, 0.2);
  border-radius: 10px;

  @media (max-width: 700px) {
    font-size: 13px;
  }
`;