import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from '../../services/api';
import { FiLogOut } from 'react-icons/fi';

import UsersList from '../../components/UsersList';
import AdminsList from '../../components/AdminsList';
import { Admin, User, useAuth } from '../../hooks/auth';
import logoImg from '../../assets/logo.png';

import {
  Container,
  UserHeader,
  Header,
  HandleBox,
} from './styles';
import { useHistory } from 'react-router-dom';

const Dashboard: React.FC = () => {
  const [users, setUsers] = useState([] as User[]);
  const [admins, setAdmins] = useState([] as Admin[]);
  const [inputValue, setInputValue] = useState('');
  const [filterUsers, setFilterUsers] = useState([] as User[]);
  const [filterAdmins, setfilterAdmins] = useState([] as Admin[]);
  const [currentView, setCurrentView] = useState<'Usuários' | 'Administradores'>('Usuários')
  const inputRef = useRef<HTMLInputElement>(null);
  const { user, signOut } = useAuth()
  const history = useHistory();
  
  useEffect(() => {
    api.get('users').then(response => {
      setUsers(response.data[0]);
      setAdmins(response.data[1]);
    });
  }, []);

  useMemo(() => {
    if (currentView === 'Usuários') {
      const filteredUsers: User[] =  users.filter(user => {
        if (inputRef.current) {
          return user.name.toLowerCase().includes(inputValue.toLowerCase())
        }
      return filteredUsers;
      });
  
      setFilterUsers(filteredUsers);
    }
  }, [users, inputValue, currentView]);

  useMemo(() => {
    if (currentView === 'Administradores') {
      const filteredAdmins: Admin[] =  admins.filter(admin => {
        if (inputRef.current) {
          return admin.name.toLowerCase().includes(inputValue.toLowerCase())
        }
      return filteredAdmins;
      });
      
      setfilterAdmins(filteredAdmins);
    }
  }, [admins, inputValue, currentView]);

  const signignOut = useCallback(() => {
    signOut();

    history.push('/');
  }, [signOut, history]);

  const makeAdmin = useCallback(async(name: string, email: string, index: number) => {
    try {
      const response = await api.post('admin', {
        name,
        email
      });
  
      alert(`Você concedeu permissões de administrador para ${name}!`);

      const usersArray = [ ...users ];
      usersArray[index] = response.data[0];

      const adminsArray = [ ...admins ];
      adminsArray.push(response.data[1]);

      setUsers(usersArray);
      setAdmins(adminsArray);
    } catch (err) {
      console.log(err);
    }
  }, [users, admins]);

  const removeAdmin = useCallback(async(name: string, email: string, index: number) => {
    try {
      const response = await api.delete('admin', {
        headers: {
          'email': email
        }
      });
      alert(`Você removeu as permissões de aminstrador de ${name}!`);

      const usersArray = [ ...users ];
      usersArray[index] = response.data[0];

      let adminsArray = [ ...admins ];
      adminsArray = adminsArray.filter(admin => admin.email !== response.data[1].email);

      setUsers(usersArray);
      setAdmins(adminsArray);
    } catch (err) {
      console.log(err);
    }
  }, [users, admins]);

  const defaultEmails = useMemo(() => {
    return [
      'cassiogroh@gmail.com',
      'lpitol@gmail.com',
      'weinzierl.1976@gmail.com',
      'renanotivin@hotmail.com'
    ];
  }, []);

  const defaultAdmin = useCallback((email: string) => {
    return defaultEmails.find(defaultEmail => email === defaultEmail);
  }, [defaultEmails]);

  const groupedEmails = useMemo(() => {
    const usersEmailsArray = [] as string[];
    const adminsEmailsArray = [] as string[];
    users.map(userkey => {
      const isAdmin = admins.find(admin => admin.email === userkey.email);
      if (!isAdmin) {
        usersEmailsArray.push(userkey.email);
      } else {
        userkey.email !== user.email && adminsEmailsArray.push(userkey.email);
      }
      return true;
    });
    
    if (!usersEmailsArray.length) {
      usersEmailsArray.push('Nenhum usuário "não admin" cadastrado')
    }

    return [ usersEmailsArray, adminsEmailsArray ];
  }, [users, admins, user.email]);

  const copyEmails = useCallback(() => {
    const hiddenTextArea = document.createElement("textarea");
    document.body.appendChild(hiddenTextArea);
    hiddenTextArea.value = currentView === 'Usuários' ? groupedEmails[0].toString() : groupedEmails[1].toString();
    hiddenTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(hiddenTextArea);

    alert(`Todos os e-mails de ${currentView} foram copiados para o clipboard!`);
  }, [groupedEmails, currentView]);

  return (
    <Container>
      <UserHeader>
        Bem vindo {user.name}!
        <FiLogOut size={40} title='Fazer logout' onClick={signignOut} />
      </UserHeader>

      <Header>
        {
          currentView === 'Usuários' ?
          <h1>Lista de usuários do app mobile</h1> :
          <h1>Lista de administradores</h1>
        }
        <img src={logoImg} alt="Thermosym"/>
      </Header>

      <HandleBox>
        <input
          placeholder={'Buscar ' + (currentView === 'Usuários' ? 'usuário' : 'admin')}
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <button
          title={'Copiar e-mail de todos os ' + currentView}
          type='button'
          onClick={copyEmails}
        >
          Copiar e-mails
        </button>

        <button
          title='Trocar visualização usuários/administradores'
          type='button'
          onClick={() => currentView === 'Usuários' ? setCurrentView('Administradores') : setCurrentView('Usuários')}
        >
          {currentView}
        </button>
      </HandleBox>

      {
        currentView === 'Usuários' ?
        <UsersList
          makeAdmin={makeAdmin}
          removeAdmin={removeAdmin}
          defaultAdmin={defaultAdmin}
          filterUsers={filterUsers}
          user={user}
        /> :
        <AdminsList
          removeAdmin={removeAdmin}
          defaultAdmin={defaultAdmin}
          filterAdmins={filterAdmins}
          user={user}
        />
      }
      
    </Container>
  )
}

export default Dashboard;
