import React, { useEffect } from 'react';
import { FiFrown } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Container, AnimationContainer } from './styles';

const NotFound: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/')
    }, 3000);
  }, [history]);

  return (
    <Container>
      <AnimationContainer>
          <h1>Não encontramos a página que você está procurando.</h1>
          <FiFrown size={120} />
      </AnimationContainer>
    </Container>
  )
};

export default NotFound;