import React from 'react';
import { FiUserCheck, FiUser, FiCheckCircle } from 'react-icons/fi';

import { Admin, User } from '../../hooks/auth';

import {
  Container,
  FirstRow,
  ListView,
} from '../../pages/Dashboard/styles';

interface AdminsListProps {
  removeAdmin: (name: string, email: string, index: number) => {};
  defaultAdmin: (email: string) => string | undefined;
  filterAdmins: Admin[];
  user: User;
}

const AdminsList: React.FC<AdminsListProps> = ({
  removeAdmin,
  defaultAdmin,
  filterAdmins,
  user
}) => {
  return (
    <Container>
      <FirstRow>{filterAdmins.length} administradores econtrados</FirstRow>
      <ListView>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
          {
          filterAdmins.map((filteredAdmin, index) => (
            <tr key={filteredAdmin.id}>
              <td>{filteredAdmin.name}</td>
              <td>{filteredAdmin.email}</td>
              {
                defaultAdmin(filteredAdmin.email)
                ?
                  <td title='Administrador padrão'>
                    <FiUserCheck size={20} color='#000'/>
                  </td>
                :
                (
                  user.name === filteredAdmin.name
                  ?
                  <td title='Eu mesmo'>
                    <FiUser size={20} color='#FFF' />
                  </td>
                  :
                  <td
                    onClick={() => defaultAdmin(user.email) ?
                      removeAdmin(filteredAdmin.name, filteredAdmin.email, index) :
                      alert('É necessário ser um administrador padrão para conceder/remover permissões!')
                    }
                    title='Remover permissão'
                    style={{cursor: 'pointer'}}
                  >
                    <FiCheckCircle size={20} color='#00FF66' />
                  </td>
                )
              }
            </tr>
          ))
          }
          </tbody>
        
        </table>
      </ListView>
    </Container>
  )
}

export default AdminsList;