import React from 'react';
import { FiUserCheck, FiUser, FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { User } from '../../hooks/auth';

import {
  Container,
  ListView,
  FirstRow
} from '../../pages/Dashboard/styles';

interface UsersListProps {
  makeAdmin: (name: string, email: string, index: number) => {};
  removeAdmin: (name: string, email: string, index: number) => {};
  defaultAdmin: (email: string) => string | undefined;
  filterUsers: User[];
  user: User;
}

const UsersList: React.FC<UsersListProps> = ({
  makeAdmin,
  removeAdmin,
  defaultAdmin,
  filterUsers,
  user
}) => {
  return (
    <Container>
      <FirstRow>{filterUsers.length} usuários econtrados</FirstRow>
      <ListView>

        <table>
          
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Progresso</th>
              <th>Desde</th>
              <th>Adm</th>
            </tr>
          </thead>

          <tbody>
          {
          filterUsers.map((filteredUser, index) => (
            <tr key={filteredUser.id}>
              <td>{filteredUser.name}</td>
              <td>{filteredUser.email}</td>
              <td>{Number(filteredUser.progress).toFixed(0)} %</td>
              <td>{format(
                    new Date(filteredUser.created_at),
                    `dd'/'MMM'/'yyyy`,
                    { locale: ptBR }
                  )}
              </td>
              {
                defaultAdmin(filteredUser.email)
                ?
                  <td title='Administrador padrão'>
                    <FiUserCheck size={20} color='#000'/>
                  </td>
                :
                (
                  user.name === filteredUser.name
                  ?
                  <td title='Eu mesmo'>
                    <FiUser size={20} color='#FFF' />
                  </td>
                  :
                  <td
                    onClick={() => {
                      defaultAdmin(user.email) ?
                      (
                        filteredUser.isAdmin ?
                        removeAdmin(filteredUser.name, filteredUser.email, index) :
                        makeAdmin(filteredUser.name, filteredUser.email, index)
                      ) :
                      alert('É necessário ser um administrador padrão para conceder/remover permissões!');
                    }}
                    title={filteredUser.isAdmin ? 'Remover permissão' : 'Conceder permissão'}
                    style={{cursor: 'pointer'}}
                  >
                    {
                      filteredUser.isAdmin
                      ?
                      <FiCheckCircle size={20} color='#00FF66' />
                      :
                      <FiXCircle size={20} color='#ED4337' />
                    }
                  </td>
                )
              }
            </tr>
          ))
          }
          </tbody>
          
        </table>
      </ListView>
    </Container>
  )
}

export default UsersList;