import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --primary-color: #03AFEF;
    --text-color-light: #F4EDE8;

    font-size: 62.5%;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
  }

  html,
  body,
  #root {
    height: 100vh;
    width: 90vw;
    max-width: 1100px;
    margin: 0 auto;
    background-color: var(--primary-color);
  }

  body,
  input,
  button,
  textarea,
  p, a {
    outline: none;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6rem;
    font-weight: 400;
  }

  a {
    font-weight: 500;
  }

  button {
    cursor: pointer;
    outline: none;
  }

  h1 {
    font-family: 'Roboto Slab', serif;
    font-size: 2.5rem;
    font-weight: 500;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: var(--bg-color);
  }
`;
